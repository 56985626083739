@import url('https://fonts.googleapis.com/css?family=Questrial');
//font-family: 'Questrial', sans-serif;

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
//font-family: 'Open Sans', sans-serif;

@font-face {
    font-family: 'journalregular';
    src: url('../fonts/journal-webfont.eot');
    src: url('../fonts/journal-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/journal-webfont.woff2') format('woff2'),
         url('../fonts/journal-webfont.woff') format('woff'),
         url('../fonts/journal-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@mixin mwidth {
	padding: 0;
	width: 100%;
	max-width: $mwidth;

	@media (max-width: $mwidth) {
		padding: 0 15px;
	}
}

// Clearfix
@mixin clear() {
    &::after {
        display: table;
        content: '';
        clear: both;
    }
}

/* --- clearfix --- */
@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}
/* === use ===
.article {
     @include clearfix();
}*/

/* --- border radius --- */
@mixin border-radius($border-radius) {
	-webkit-border-radius: $border-radius; 
	-moz-border-radius: $border-radius; 
	border-radius: $border-radius; 
}

@mixin web_media($el, $name) {
	-webkit-#{$el}: $name;
	-moz-#{$el}: $name;
	-ms-#{$el}: $name;
	-o-#{$el}: $name;
	#{$el}: $name;
}

/* --- opacity --- */
@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}
/* === use === 
.article-heading {
  @include opacity(0.8);
}*/

//btn
@mixin btn {
	position: relative;
	margin: 5px 0 10px 0;
	padding: 15px 35px 15px 18px;
	display: inline-block;
	color: white;
	font-size: 1em;
	line-height: normal;
	text-decoration: none;
	text-align: left;
	white-space: nowrap;
	border: 0;
	background: $link-blue;

	@include border-radius(2px);
	@include web_media(box-shadow, 0px 2px 1px rgba(0,0,0,.4));

	@media (max-width: $mobile) {
		width: 100%;
	}

	/*&:after {
		position: absolute;
		right: 10px;
		margin-top: 4px;
		float: right;
		display: inline-block;
		content: " ";
		border-top: 6px solid transparent;
		border-bottom: 6px solid transparent;
		border-left: 12px solid white;
	}*/

	&:active {
		position: relative;
		top: 1px;
		background-color: #94d5ff;
	} 

	&:hover, &:focus, &:active {
		color: white;
	}
}

//homebox-btn
@mixin homebox-btn {
	display: inline-block;
	padding: 15px 5px;
	width: 100%;
	background-color: $menu-bgr;
	font-size: 16px;
	line-height: 1;
	font-weight: 600;
	color: #fff;
	text-transform: uppercase;
	@include border-radius(5px);

	@media (min-width: $screen-md-min) {
		font-size: 20px;
	}
}

@mixin homebox-btn-blue {
	display: inline-block;
	padding: 15px 5px;
	width: 100%;
	background-color: $menu-bgr;
	font-size: 16px;
	line-height: 1;
	font-weight: 600;
	color: #0054a4;
	text-transform: uppercase;
	@include border-radius(5px);

	@media (min-width: $screen-md-min) {
		font-size: 20px;
	}
}

@mixin location-inner-btn {
	display: inline-block;
	padding: 15px 5px;
	width: 100%;
	background-color: $menu-bgr;
	font-size: 16px;
	line-height: 1;
	font-weight: 600;
	color: $menu-bgr-blue;
	text-transform: uppercase;
	@include border-radius(5px);

	@media (min-width: $screen-md-min) {
		font-size: 20px;
	}
}

//data-number
@mixin data-number {
	padding: 10px 0;
	font-size: 16px;
	line-height: 1;
	color: #000;

	@media (min-width: $screen-sm-min) {
		padding: 15px 0;
		font-size: 18px;
	}
	@media (min-width: $screen-md-min) {
		padding: 20px 0 15px;
		font-size: 22px;
	}

	span:before {
		content: attr(data-number);
	}
}