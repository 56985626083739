/*****auto_office*****/
.auto_office h3 {
  font-size: 30px;
  color: #000000;
  font-weight: 800;
  text-align: center;
  margin-bottom: 50px;
}
.table_sec {
  width: 497px;
  margin: 0 auto;
}
.table_sec .table thead tr th {
  background-color: #20419a;
  color: #ffffff;
  font-weight: 400;
}
.table_sec .table tr td {
  font-size: 15px;
  color: #000000;
  font-weight: 400;
}
.table_sec .table tr td:nth-child(3) {
  text-align: start;
}
.table_sec .table tr td span {
  color: #a2a3a3;
}
