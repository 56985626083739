.footer {
  padding: 40px 0px;
  background-color: #212f3d;
}
.footer_left p {
  font-size: 18px;
  color: #ffffff;
  font-weight: 300;
  margin-bottom: 20px;
}
.footer_left p a {
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
}
.footer_left p a:hover {
  color: #00aeef;
}
.footer_top {
  align-items: center;
  padding-bottom: 95px;
}
.footer_right {
  text-align: right;
}
.footer_txt {
  margin-right: 100px;
  display: inline-block;
  vertical-align: middle;
}
.footer_txt img {
  width: 55px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
}
.footer_txt p {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
  color: #ffffff;
  font-weight: 400;
  text-align: left;
}
.footer_txt p a {
  display: block;
  font-size: 23px;
  color: #ffffff;
  font-weight: 700;
}
.footer_txt p a:hover {
  color: #00aeef;
}
.footer_logo {
  width: 130px;
  display: inline-block;
  vertical-align: middle;
}
.footer_logo img {
  width: 100%;
}
.footer_btm {
  text-align: center;
}
.footer_btm p {
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
}

.footer {
  .footer-tevreden-wrapper {
    min-width:158px;
  }

}

@media screen and (max-width: 500px) {
  .footer_right {
    flex-direction: column;
    align-items: flex-start!important;
  }
}