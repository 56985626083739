.inner_banner1 .banner_txt h3 {
	margin-bottom: 25px;
}
.inner_banner1 .banner_txt p {
	font-size: 18px;
	line-height: 22px;
	color: #ffffff;
	font-weight: 400;
	margin-bottom: 25px;
}
.banner_txt_vestigingen {
	float: right;
}
.innservice_list {
	padding: 20px 0px;
}
.innservice_list ul li .service_imgbox img {
	height: 40px;
	object-fit: contain;
}
.autoshade_sec {
	padding: 30px 0px 20px;
}
.autoshade_main {
	margin-bottom: 40px;
}
.autoshade_title h1,
.autoshade_title h2 {
	text-align: center;
	margin-top: 50px;
	margin-bottom: 50px;
}
.autoshade_title h2,
.autoshade_title h1 {
	font-size: 36px;
	color: #000000;
	font-weight: 800;
	padding-bottom: 20px;
	position: relative;
}
.autoshade_txt h2 a {
	color: inherit;
}
.autoshade_title h2:before,
.autoshade_title h1:before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	width: 232px;
	height: 2px;
	margin: 0 auto;
	background-color: #000b8d;
}
.page_title_vestigingen h2:before {
	width: 300px;
}
.autoshade_box {
	background-color: #f3f3f3;
	margin-bottom: 20px;
	display: flex;
}
.autoshade_leftbox {
	background-color: #f3f3f3;
	padding: 25px 15px;
	width: 68%;
	float: left;
}
.autoshade_img {
	width: 245px;
	display: inline-block;
	vertical-align: top;
	margin-right: 12px;
}
.autoshade_img img {
	width: 100%;
}
.autoshade_txt {
	width: 65%;
	display: inline-block;
	vertical-align: top;
}
.autoshade_txt h4,
.autoshade_txt h2 {
	font-size: 22px;
	color: #000000;
	font-weight: 700;
}
.autoshade_locate {
	margin-bottom: 3px;
}
.autoshade_locate p {
	font-size: 17px;
	color: #000000;
	font-weight: 400;
	margin-right: 10px;
	display: inline-block;
	vertical-align: bottom;
}
.autoshade_locate span {
	font-size: 17px;
	color: #000000;
	font-weight: 400;
	display: inline-block;
	vertical-align: bottom;
}
.autoshade_locate span img {
	width: 21px;
	display: inline-block;
	vertical-align: bottom;
	position: relative;
	top: -4px;
}
.autoshade_rate {
	margin-bottom: 5px;
}
.autoshade_rate span.checked {
	color: orange;
}
.autoshade_rate p {
	margin-left: 10px;
}
.autoshade_rate ul {
	display: inline-block;
	vertical-align: middle;
	margin-right: 8px;
}
.autoshade_rate ul li {
	display: inline-block;
	vertical-align: top;
	margin-right: 4px;
}
.autoshade_rate ul li img {
	width: 22px;
}
.autoshade_rate p {
	font-size: 20px;
	color: #000000;
	font-weight: 400;
	display: inline-block;
	vertical-align: middle;
	line-height: normal;
}
.autoshade_para p {
	font-weight: 300;
}
.autoshade_rightbox {
	background-color: #f9f9f9;
	padding: 27px 5px 25px 30px;
	width: 32%;
	float: left;
}
.autoshade_btn {
	margin-bottom: 20px;
}
.autoshade_btn ul li {
	display: inline-block;
	vertical-align: top;
	margin-right: 3px;
}
.autoshade_btn ul li:last-child {
	margin-right: 0px;
}
.autoshade_btn ul li a.btn-border {
	display: inline-block;
	border-radius: 2px;
	background-color: #f9f9f9;
	border: 1px solid #999999;
	font-size: 17px;
	color: #000000;
	font-weight: 300;
	padding: 10px 15px;
	width: 161px;
	height: 47px;
	text-align: center;
}
.autoshade_btn ul li a.btn-border:hover {
	background-color: #f0ad4e;
	border: 1px solid #f0ad4e;
	color: #ffffff;
}
.autoshade_btn ul li a.btn {
	display: inline-block;
	font-size: 16px;
	line-height: 22px;
	color: #ffffff;
	padding: 13px 3px;
	width: 156px;
	height: 48px;
	border-radius: 2px;
	background-color: #f0ad4e;
	text-align: center;
	border: 1px solid #f0ad4e;
}
.autoshade_btn ul li a.btn:hover {
	background-color: #f9f9f9;
	border: 1px solid #999999;
	color: #000000;
}
.autoshade_txtright {
	display: flex;
}
.autoshade_txtright strong {
	display: inline-block;
	vertical-align: top;
	font-size: 18px;
	color: #000000;
	font-weight: 700;
	width: 45px;
	height: 45px;
	border-radius: 23px;
	background-color: #f9f9f9;
	border: 1px solid #000b8d;
	text-align: center;
	line-height: 43px;
	margin-right: 5px;
}
.autoshade_txtright p {
	display: inline-block;
	vertical-align: top;
	font-size: 16px;
	color: #000000;
	width: 84%;
	font-family: "MyriadPro-Regular", sans-serif;
	align-self: center;
}
.autoshade_loadbtn {
	text-align: center;
}
.autoshade_loadbtn a {
	font-size: 16px;
	color: #ffffff;
	font-weight: 400;
	display: inline-block;
	border-radius: 5px;
	background-color: #00aeef;
	padding: 19px 30px;
}
.autoshade_loadbtn a:hover {
	background-color: #000b8d;
}
.content {
	display: none;
}
.autoshade_loadbtn a.noContent {
	display: none;
}

.video-wrapper {
	position: relative;
	padding-bottom: 56.25% !important;
	height: 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.high-tech-indicator {
	width:150px;
	height:150px;
	
}


@media screen and (max-width: 500px) {

	.body-locations {

		.user-location-search {
			flex-direction: column;
		}

		.location-list {
			.autoshade_box  {
				flex-direction: column;
			}
		}
	}

}