.tooltip-wrapper {
  position:relative;

  .tooltip-indicator {
    margin-left:5px;
  }

  .tooltip-text {
    width:300px;
    left:-30px;
    margin-bottom: 15px;
    display: none;
    opacity: 0;
    
    @media screen and (max-width:445px) {
      left:-115px;
    }

    &::after {
      content: "";
      border-width: 10px;
      height: 30px;
      width: 20px;
      position: absolute;
      left: 33px;
      bottom: -30px;
      border-style: solid;
      border-color: #fff transparent transparent transparent;
      
      @media screen and (max-width:445px) {
        left:calc(50% - 32px);
      }
    }
  }

  .tooltip-indicator:hover + .tooltip-text {
    display: block;
    opacity: 1;
    
  }

  
}
