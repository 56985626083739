.car_sec {
	padding: 67px 0px;
	margin-bottom: 50px;
	background-color: #f3f3f3;
}

.car_sec h2 {
	font-size: 36px;
	color: #000000;
	font-weight: 800;
	text-align: center;
	padding-bottom: 20px;
	margin-bottom: 20px;
	position: relative;
}

.car_sec h2:before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	width: 232px;
	height: 2px;
	margin: 0 auto;
	background-color: #000b8d;
}

.car_sec h4 {
	font-size: 20px;
	color: #000000;
	font-weight: 400;
	text-align: center;
	padding-bottom: 30px;
}

.car_sec p {
	font-size: 20px;
	color: #000000;
	text-align: center;
	font-weight: 300;
	width: 94%;
	margin: 0 auto;
}

.video_sec {
	padding-bottom: 145px;
}

.video_main {
	margin-top: -222px;
}

.video_sec h3 {
	font-size: 36px;
	line-height: 30px;
	color: #000000;
	font-weight: 800;
	margin-bottom: 35px;
	text-align: center;
}

.video_box {
	max-width: 574px;
	margin: 0 auto;
	position: relative;
	flex-wrap: wrap;
	margin-top: 20px;
}

.video_box img {
	width: 100%;
}

.video_box a.video_btn {
	position: absolute;
	width: 98px;
	height: 98px;
	top: 50%;
	left: 0;
	right: 0;
	margin: 0 auto;
	transform: translateY(-57%);
}

.vacature-container {
	margin-top: 50px;
}

.hovereffect {
	width: 100%;
	overflow: hidden;
	position: relative;
	cursor: default;
	margin-bottom: 20px;
}

.hovereffect .overlay {
	width: 100%;
	position: absolute;
	overflow: hidden;
	bottom: 0;
	left: 0;
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

.hovereffect:hover .overlay {
	background-color: #00aeef;
	height: 100%!important;
}

.overlay {
	height: 60px;
}

.hovereffect  {
	img{
		width: 100%;
		display: block;
		position: relative;
		height: 497px;
		object-fit: cover;

		&.object-fit-contain {
			object-fit: scale-down;
		}

	}
}
.hovereffect h2 {
	text-align: left;
	position: relative;
	padding: 16px 15px 18px;
	background: #000b8d;
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	font-size: 22px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 400;
}

.hovereffect p {
	color:#fff;
	padding: 0 15px;
	margin-bottom: 10px;
	font-size:15px;
}

.hovereffect:hover h2 {
	-webkit-transform: translateY(0px);
	-ms-transform: translateY(0px);
	transform: translateY(0px);
	font-size: 24px;
	line-height: 30px;
	color: #ffffff;
	font-weight: 700;
	background: #00aeef;
}

.blue-btn {
	display: flex;
	width: 112.5px;
	justify-content: center;
	margin: auto;
	margin-top: 10px;
	color: white;
	background-color: blue;
	padding: 8px 10px;
	border-radius: 5px;
	border: unset;
	transition: 0.2s;
}

.blue-btn:hover {
	background-color: rgb(39, 39, 254);
	color: white;
	cursor: pointer;
	padding: 10px 12px;
}

.blue-btn:active {
	background-color: rgb(3, 3, 212);
}

.video-container {
	display: flex;
	justify-content: center;
	float: left;
}

.partner_sec {
	padding: 20px 0px 100px;
	background-color: #ffffff;
}

.partner_title {
	text-align: center;
	margin-bottom: 63px;
}

.partner_title h3, 
.partner_title p{
	font-size: 36px;
	color: #000000;
	font-weight: 800;
	position: relative;
	display: inline-block;
	padding-bottom: 20px;
}

.partner_title h3:before,
.partner_title p:before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	width: 90%;
	height: 1px;
	margin: 0 auto;
	background-color: #000b8d;
}

.partner_sec ul {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 1010px;
	margin: 0 auto;
}

.partner_sec ul li {
	display: inline-block;
	vertical-align: middle;
	margin-right: 60px;
}

.partner_sec ul li:nth-last-of-type(2) {
	margin-right: 25px;
	max-width: 250px;
}

.partner_sec ul li:last-child {
	margin-right: 0px;
	max-width: 230px;
}

.partner_sec ul li img {
	height: 140px;
	object-fit: contain;
}

.map-wrapper {
	position:relative;
	
	.banner_txt.banner_txt_vestigingen{
		float:unset;
	}
}

.video_box {
	h1,h2,h3,h4 {
		font-weight:500;
		margin-bottom:10px;
	}

	.embeddedContent {
		position: relative;
    padding-bottom: 56.25% !important;
    height: 0;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.calamiteiten-wrapper {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	margin-right: 3rem;
	background-color:#fff;
	border-radius: 10px;
	right: 0;
	left: auto;
	padding: 10px;
}

.center-ul ul {
	justify-content: center;
}

.map-wrapper {
	@media screen and (max-width: 500px) {
		.user-location-search {
			display: flex;
			flex-direction: column;

			#addressInput {
				width:100%;
				margin-bottom: 5px;
			}
		}
	}
}

#home-map .select_location{
	display: none;
}

.map-wrapper.has-calamity-alert {
	@media screen and (max-width: 990px) {

		display: flex;
		flex-wrap: wrap;

		#home-map {
			flex-grow: 1;
		}

		.banner_main,
		.calamiteiten-wrapper {
			position:unset;
			transform: unset;
			width: 50%;
			flex: 0 0 50%;
		}

		.calamiteiten-wrapper {
			margin-right:0;
			background-color: $asn-dark-blue;
			border-radius: 0;
		}

		.banner_main {
			.banner_txt {
				width: 100%;
				margin-left: 0 !important;
				border-radius: 0;
				max-width: unset;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}
	}
	@media screen and (max-width: 770px) { 
		.banner_main,
		.calamiteiten-wrapper {
			flex-grow: 1;
			flex: 0 0 100%;
			width: 100%;
		}
	}
}