.service_list {
  background-color: #00aeef;
  padding: 12px 0px;
}
.service_list ul {
  max-width: 1010px;
  margin: 0 auto;
}
.service_list ul li {
  display: inline-block;
  vertical-align: top;
  width: 19%;
  margin-right: 8px;
}
.service_list ul li:last-child {
  margin-right: 0px;
}
.service_list ul li .service_imgbox {
  width: 60px;
  margin: 0 auto;
  margin-bottom: 8px;
}
.service_list ul li .service_imgbox img {
  width: 100%;
}
.service_list ul li .service_txtbox h4 {
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
}
