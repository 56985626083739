//== Bootstrap overrides

// size
$desktop: 1366px;
$laptop: 992px;
$tablet: 767px;
$mobile: 640px;
$mobile-mini: 480px;

$mwidth: 1170px;

$image-path: '../images/';

// Typography
$font-size-base: 16px;
$line-height-base: 1.5;

$border-radius-base:  0;
$border-radius-large: 0;
$border-radius-small: 0; 
   
//colors
$selection-blue: #026cab;

$main-gray: #444;
$txt-gray: #747474;
$txt-black: #151515;
$dark-blue: #2d468e;
$light-blue: #44B5FF;
$link-blue: #5ABDEC;
$asn-blue: rgb(32, 65, 154);
$asn-dark-blue: #000b8d;
$gold: #f0ad4e;

$dark-blue-bgr: #2F488C; 
$light-blue-bgr: #08c2ff;
$light-gray-bgr: #f4f4f4;

$footer-bgr: #20419a;
/*$footer-bgr: #023385;*/
$menu-bgr: #f0ad4e;
$menu-bgr-blue: #20419a;


// Inverted navbar links
$navbar-inverse-link-color:                 #999;
$navbar-inverse-link-active-bg:             rgba(#000, .5);


//== Fancybox image path
$fancybox-image-url: '/components/fancybox/source';

//== Font awesome files path
$fa-font-path: "/fonts/font-awesome";

// Map sizing
$step2-map-width: 100%;
$step2-map-height: 300px;

// Spacing
// You can add more entries to the $spacers map, should you need more variation.
$spacer:   1rem; //root
$spacer-x: $spacer;
$spacer-y: $spacer;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: $spacer-x,
    y: $spacer-y
  ),
  2: (
    x: ($spacer-x * 1.5),
    y: ($spacer-y * 1.5)
  ),
  3: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  )
);
