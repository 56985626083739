.vacature-container {
  .hovereffect {
    .overlay {
      height:90px;

      h2{
        font-size:18px;
        min-height:90px;
      }
    }
  }
}