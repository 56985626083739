.cookie-banner {
  --card-width: 500px;
  --cookie-blue: #183174;
  
  position: relative;
  z-index: 9999;

  .cookie-overlay {
    & > * {
      border-radius: 10px;
      transition: ease-in-out .3s;
    }
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: ease-in-out .3s;

    z-index: 999;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 30px;
    .cookie-card {
      width: 100%;
      max-width: var(--card-width);
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      display: none;
    }

    .cookie-preferences {
      height: calc(100dhv - 60px);
      height: calc(100vh - 60px);
      width: 100%;
      max-width: var(--card-width);
      background-color: #fff;
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      display: none;

      .cookie-pref-texts {
        overflow: auto;
        max-height: 100%;
        padding-bottom: 80px;
        .cookie-type-excerpt {
          border-bottom: solid 1px #ccc;
          margin-bottom: 20px;

          .cookie-excerpt-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom:20px;

            p {
              font-size: 20px;
              font-weight: 600;
            }

            label {
              input {
                position: absolute;
                opacity: 0;
                visibility: hidden;

                &:disabled + span {
                  opacity: 0.8;
                }

                &:checked + span {
                  background-color: var(--cookie-blue);

                  &::after {
                    background-color: #fff;
                    transform: translateX(calc(100% + 8px));
                  }
                }
              }

              span {
                width: 40px;
                height: 20px;
                background-color: #ccc;
                display: flex;
                padding: 4px;
                border-radius: 20px;
                cursor: pointer;
                transition: ease-in-out 0.3s;

                &::after {
                  content: "";
                  width: 12px;
                  height: 12px;
                  background-color: #fff;
                  border-radius: 100%;
                  transition: ease-in-out 0.3s;
                }
              }
            }
          }

          .cookie-excerpt-body {
            margin-bottom: 20px;
          }
        }
      }

      .give-some-love-link {
        display: block;
        font-size: 12px;
        text-align: center;
        padding-bottom: 10px;
        width: 100%;
        text-decoration: none;
        color: inherit;
        transition: ease-in-out 0.2s;

        &:hover {
          color: var(--cl-primary-color);
        }

        img {
          max-width: 140px;
        }

        span {
          display: block;
          font-size: 0.8rem;
        }
      }
    }
  }

  .cookie-preferences-change {
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }

    position: fixed;
    bottom: 0;
    left: 0;
    width: 90px;
    height: 90px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    transition: ease-in-out 0.3s;

    .cookie-pref-icon-wrapper {
      max-width: 43px;
      max-height: 43px;
      width: 100%;
      height: 100%;
      background-color: var(--cookie-blue);
      padding: 7px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
      svg {
        fill: #fff;
        width: 100%;
        height: 100%;
      }
    }
  }

  &.notice,
  &.preferences {
    .cookie-overlay {
      opacity: 1;
      pointer-events: all;
      visibility: visible;
    }
  }

  &.notice {
    .cookie-card {
      opacity: 1;
      pointer-events: all;
      visibility: visible;
      display: block;
    }
  }

  &.preferences {
    .cookie-preferences {
      opacity: 1;
      pointer-events: all;
      visibility: visible;
      display: block;
    }
  }

  &.toggle-preferences {
    .cookie-preferences-change {
      opacity: 1;
      visibility: visible;
    }
  }
}

@media screen and (max-width:520px) {
  .cookie-banner {

    .cookie-pref-texts {
      padding-bottom:100px!important;
    }

    .cookie-pref-actions,
    .cookie-action {
      flex-direction: column;

      button:first-of-type{ 
        margin-bottom:7px;
      }
    }
  }
}
