.quick-repair-container,
.calamity-container {
  margin-top: 25px;
  border: solid 1px #ccc;
  border-radius: 10px;
  padding: 10px;
  display: none;

  p.h3,
  h3 {
    border-bottom: solid 1px #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .popup-progress-bar {
    position: relative;

    .progress-bar-labels {
      position: absolute;
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }

    .progress {
      height: 23px;
      display: flex;

      .progress-bar {
        float: unset;

        &.step-1 {
          width: 0%;
        }

        &.step-2 {
          width: 33.333%;
        }

        &.step-3 {
          width: 66.666%;
        }

        &.send {
          width: 100%;
        }
      }
    }
  }

  #quickrepair-map,
  #calamity-map {
    height: 300px;
  }
}

.calamity-container {
  .company-search-wrapper {
    position: relative;

    .company-results {
      display: none;
      position: absolute;
      top: 100%;
      margin-top: 5px;
      border: solid 1px #ccc;
      border-radius: 10px;
      background-color: #fff;
      width: 100%;
      box-shadow: 0 7px 10px rgba(0, 0, 0, 0.2);
      overflow: hidden;

      .company-result {
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
          background-color: #ccc;
        }

        &:not(:last-of-type) {
          border-bottom: solid 1px #ccc;
        }
      }
    }
  }
}

.afspraak-maken.make-quickrepair {
  padding: 14px 12px;
  background-color: #f0ad4e;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  font-weight: 700;
  transition: ease-in-out 0.3s;

  &:hover {
    background-color: $asn-blue;
  }
}

.embeddedContent iframe {
  width: 100%;
}
