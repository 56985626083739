.insurancecompany-list-item-link {
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
}

.locations-container ul li {
  display: flex;
  align-items: center;
  border: solid 1px #ccc;
  border-radius: 5px;
  margin: 5px 0;
  padding: 5px;
}

.locations-container ul li span:not(:last-of-type) {
  margin-right: 10px;
}

.locations-container ul li span:last-of-type {
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
  padding-right: 20px;
}

.locations-container ul li span .btn-new {
  padding: 10px 20px;
  background-color: #20419a;
  color: #fff;
  border-radius: 5px;
}

.insurance-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
}

.insurance-popup-content {
  padding: 10px 20px;
  width: 100%;
  max-width: 730px;
  background-color: #fff;
  border-radius: 5px;
  max-height: 800px;
  overflow-y: auto;
  position: relative;
}
.insurance-company-content {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
}
.insurance-company-content .insurancecompany-body {
  margin-top: 20px;
}
.insurance-popup-content i.hidden {
  display: block !important;
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 20px;
}
.insurance-popup-content i:hover {
  cursor: pointer;
}
.insurance-popup-content::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

.insurance-popup-content::-webkit-scrollbar-track {
  background: #ccc; /* color of the tracking area */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.insurance-popup-content::-webkit-scrollbar-thumb {
  background-color: #20419a; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 0; /* creates padding around scroll thumb */
}

.insurance-popup-content .locations-container img {
  border-radius: 5px;
}

.insurancecompany-list-list {
  .row {
    display: flex;
    margin-bottom: 15px;

    .insurancecompany-list-item {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 80%;
      }
    }
  }
}

.locations-container {
  a {
    position: relative;
    padding-left: 25px;
  }
  .tel::before,
  .email::before {
    position: absolute;
    top: 1px;
    left: 0;
    font-family: "FontAwesome";
    color: #1c1c1c;
  }
  .tel:before {
    content: "\f095";
  }
  .email:before {
    content: "\f0e0";
  }
}
#insurance-map {
  min-height: 400px;
  // max-width:400px;
}
.insurance-popup-content .insurancecompany-image {
  max-width: 270px;
}
@media screen and (max-width: 1170px) {
  .insurance-companies-container {
    padding: 20px;
  }
}
@media screen and (max-width: 600px) {
  .locations-container ul li {
    flex-direction: column;
  }
  .insurancecompany-list-list .row {
    flex-direction: column;
    margin-bottom: 0;
  }
  .insurancecompany-list-list .row .insurancecompany-list-item {
    margin-bottom: 15px;
  }
}
