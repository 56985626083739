@import 'node_modules/jquery-ui/themes/base/core';
@import "node_modules/jquery-ui/themes/base/datepicker";
// Save if needed at a later point
// @import "node_modules/jquery-ui/themes/base/accordion";
// @import "node_modules/jquery-ui/themes/base/autocomplete";
// @import "node_modules/jquery-ui/themes/base/button";
// @import "node_modules/jquery-ui/themes/base/checkboxradio";
// @import "node_modules/jquery-ui/themes/base/controlgroup";
// @import "node_modules/jquery-ui/themes/base/dialog";
// @import "node_modules/jquery-ui/themes/base/draggable";
// @import "node_modules/jquery-ui/themes/base/menu";
// @import "node_modules/jquery-ui/themes/base/progressbar";
// @import "node_modules/jquery-ui/themes/base/resizable";
// @import "node_modules/jquery-ui/themes/base/selectable";
// @import "node_modules/jquery-ui/themes/base/selectmenu";
// @import "node_modules/jquery-ui/themes/base/sortable";
// @import "node_modules/jquery-ui/themes/base/slider";
// @import "node_modules/jquery-ui/themes/base/spinner";
// @import "node_modules/jquery-ui/themes/base/tabs";
// @import "node_modules/jquery-ui/themes/base/tooltip";
@import 'node_modules/jquery-ui/themes/base/theme';

.ui-widget {
    font-family: inherit;
    font-size: 1em;
}

#ui-datepicker-div{
    box-shadow: 1px 4px 10px rgba(0,0,0,0.44);
}

.ui-datepicker .ui-datepicker-header{
    background: #EFF4F8;
    color: #2F488C;
}


.ui-widget-content a {
    color: #222222;
}
.ui-widget-header {
    border:0;
    background: #cccccc url(images/ui-bg_highlight-soft_75_cccccc_1x100.png) 50% 50% repeat-x;
    color: #222222;
    font-weight: bold;
}
