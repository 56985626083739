.popup {
  align-items: center;
  background-color: rgba(0,0,0,.3);
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;

  .popup-content {
    background-color:#fff;
    padding:20px;
    border-radius:10px;
    position:relative;
    z-index: 2;
    max-width:600px;
    max-height:80%;
    overflow: auto;
    text-align: left;

    &::-webkit-scrollbar {
      width: 5px; /* width of the entire scrollbar */
    }
    
    &::-webkit-scrollbar-track {
      background: #ccc; /* color of the tracking area */
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: #20419a; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 0; /* creates padding around scroll thumb */
    }
  }

  &> .close-popup {
    z-index:1;
    position:absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.nav_log.dropdown {
  #login-form {
    padding-left: 10px;
    padding-right:10px;
  }

  
  .dropdown-menu:before {
    position: absolute;
    top: -7px;
    right: 9px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
  }

  .dropdown-menu:after {
    position: absolute;
    top: -6px;
    right: 10px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: '';
  }
}