@media screen and (max-width: 1279px) {
  .banner_sec img {
    height: 680px;
  }
  .navbar-dark .navbar-brand {
    margin-right: 30px;
  }
  .nav-menu ul li {
    margin-right: 13px;
  }
  .autoshade_img {
    margin-right: 10px;
  }
}
@media screen and (max-width: 1179px) {
  .navbar-dark .navbar-brand {
    width: 140px;
    margin-right: 15px;
  }
  .nav-menu ul li {
    margin-right: 8px;
  }
  .nav_search {
    margin-right: 0px;
    margin-right: 10px;
  }
  .container,
  .main_sec .container {
    width: 97%;
  }
  .searchbox {
    width: 147px;
  }
  .nav_btn a {
    font-size: 15px;
    color: #ffffff;
    padding: 14px 7px;
  }
  .nav_log a {
    font-size: 15px;
  }
  .banner_sec {
    padding-top: 88px;
  }
  .hovereffect h2 {
    font-size: 20px;
    padding: 16px 15px 18px;
  }
  .hovereffect p.info {
    padding: 20px 15px;
  }
  .autoshade_txt {
    width: 60%;
  }
  .autoshade_leftbox {
    width: 65%;
  }
  .autoshade_rightbox {
    width: 35%;
  }
  .autoshade_btn ul li a.btn-border {
    width: 150px;
  }
  .autoshade_img {
    width: 234px;
  }
  .customer_right {
    padding-left: 90px;
  }
}
@media screen and (max-width: 1079px) {
  .nav-icon {
    display: block;
  }
  body.open {
    overflow: hidden;
  }
  .nav-menu {
    display: none;
    position: fixed;
    top: 87px;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    float: none;
    z-index: 9999;
    background: #ffffff;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    overflow: auto;
    max-height: calc(100vh - 87px);
    max-height: -webkit-calc(100vh - 87px);
    max-height: -moz-calc(100vh - 87px);
    padding: 0px;
  }
  .open .nav-menu {
    display: block;
    padding: 25px 28px 0px;
  }
  nav ul {
    width: 100%;
    max-width: 100%;
    float: none;
    display: block;
    justify-content: normal;
    margin: 0px;
    padding: 0px 25px;
    margin-bottom: 50px;
  }
  .navbar-nav {
    display: block;
  }
  .nav-menu ul li {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .nav_search,
  .nav_btn {
    margin-bottom: 15px;
  }
  .searchbox {
    width: 185px;
  }
  .nav_btn a {
    padding: 14px 12px;
  }
  .service_list ul li {
    margin-right: 18px;
    width: 18%;
  }
  .main_sec .service_list ul li {
    margin-right: 5px;
  }
  .repair_left {
    padding-right: 15px;
  }
  .autoshade_btn ul li a.btn-border {
    width: 122px;
    padding: 10px 10px;
  }
  .autoshade_txt {
    width: 56%;
  }
  .autoshade_locate p {
    margin-right: 0px;
    display: block;
    margin-bottom: 10px;
  }
  .customer_right {
    padding-left: 55px;
  }
  .isotope-item {
    width: 31.6%;
  }
  .grid_txt {
    width: 70%;
  }
  .desktop-view {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .service_list ul li {
    margin-right: 13px;
  }
  .partner_sec ul li {
    margin-right: 35px;
  }
  .partner_sec ul li:nth-last-of-type(2) {
    margin-right: 20px;
    max-width: 200px;
  }
  .partner_sec ul li:last-child {
    margin-right: 0px;
    max-width: 200px;
  }
  .footer_txt {
    margin-right: 30px;
  }
  .main_sec .service_list ul li {
    margin-right: 3px;
  }
  .repair_left {
    padding-right: 10px;
  }
  .repair_iframe iframe {
    height: 195px;
  }
  .amsterdam_right {
    padding-right: 0px;
  }
  .inn_video {
    padding-bottom: 130px;
  }
  .autoshade_leftbox {
    width: 62%;
  }
  .autoshade_img {
    width: 150px;
  }
  .autoshade_txt {
    width: 59%;
  }
  .autoshade_rightbox {
    width: 38%;
    padding: 27px 5px 25px 12px;
  }
  .autoshade_btn ul li a.btn-border {
    width: 100px;
    padding: 11px 6px;
    height: 45px;
    font-size: 15px;
  }
  .autoshade_btn ul li a.btn {
    font-size: 15px;
    padding: 10px 3px;
    width: 141px;
    height: 45px;
  }
  .autoshade_txt h4 {
    font-size: 20px;
  }
  .autoshade_locate span img {
    width: 16px;
  }
  .autoshade_rate ul li img {
    width: 19px;
  }
  .autoshade_loadbtn a {
    padding: 16px 30px;
  }
  .autoshade_sec {
    padding: 10px 0px 20px;
  }
  .customer_right {
    margin-top: 50px;
    padding-left: 0;
  }
  .total_leftbox {
    margin-right: 8px;
  }
  .total_leftbox img {
    width: 40px;
  }
  .total_leftbox p strong {
    font-size: 18px;
  }
  .total_rightbox p img {
    width: 28px;
  }
  .isotope-item {
    width: 31%;
  }
}
@media screen and (max-width: 767px) {
  .service_list ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .service_list ul li,
  .main_sec .service_list ul li {
    margin: 0px 10px 20px;
    width: 30%;
  }
  .footer_right {
    text-align: left;
  }
  .hovereffect img {
    height: 497px;
  }
  .hovereffect h2 {
    font-size: 18px;
  }
  .hovereffect:hover h2 {
    font-size: 20px;
  }
  .hovereffect p.info {
    font-size: 18px;
  }
  .video-container {
    display: contents;
    margin: auto;

    .pr-1 {
      padding-right: 0 !important;
    }
    .pl-1 {
      padding-left: 0 !important;
    }
  }
  .partner_sec ul li {
    margin-right: 15px;
  }
  .partner_sec ul li:nth-last-of-type(2) {
    margin-right: 15px;
    max-width: 180px;
  }
  .partner_sec ul li:last-child {
    margin-right: 0px;
    max-width: 170px;
  }
  .video_sec h3,
  .car_sec h2,
  .partner_title h3,
  .inner_banner_title h3,
  .autoshade_title h2 {
    font-size: 30px;
  }
  .inner_banner {
    padding: 30px 0px 0px;
  }
  .repair_main {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .repair_btn {
    margin-bottom: 35px;
  }
  .repair_videobox {
    margin-bottom: 35px;
  }
  .main_sec {
    padding-bottom: 20px;
  }
  .partner_sec {
    padding: 50px 0px 30px;
  }
  .video_sec {
    padding-bottom: 80px;
  }
  .video_box a.video_btn {
    width: 80px;
    height: 80px;
  }
  .repair_iframe iframe {
    height: 300px;
  }
  .repair_left h4,
  .repair_list h4,
  .repair_videobox h4,
  .amsterdam_right h4 {
    font-size: 22px;
  }
  .repair_left {
    padding-right: 0;
  }
  .amsterdam_right {
    margin-bottom: 40px;
  }
  .amsterdam_btn ul {
    text-align: left;
  }
  .amsterdam_main {
    padding: 30px 0px;
  }
  .partner_title {
    margin-bottom: 30px;
  }
  .autoshade_leftbox {
    width: 53%;
  }
  .autoshade_rightbox {
    width: 47%;
  }
  .autoshade_img {
    width: 100%;
    margin: 0;
    margin-bottom: 15px;
  }
  .autoshade_txt {
    width: 100%;
  }
  .inner_banner2 {
    margin-bottom: 50px;
  }
  .customer_left {
    padding-right: 0px;
    margin-bottom: 40px;
  }
  .customer_right h5 {
    margin-bottom: 20px;
  }
  .total_leftbox {
    margin-right: 15px;
  }
  .total_box {
    margin-bottom: 20px;
  }
  .isotope-item {
    width: 47.2%;
  }
  .banner_txt_vestigingen {
    margin: auto;
  }
}
@media screen and (max-width: 639px) {
  .hovereffect h2 {
    font-size: 16px;
  }
  .video_box {
    width: 100%;
  }
  .isotope-item {
    width: 46.9%;
  }
  .customer_main {
    margin-bottom: 70px;
  }
  .review_sec h3 {
    margin-bottom: 30px;
  }
  .review_sec {
    padding-bottom: 70px;
  }
  .autoshade_leftbox {
    width: 49%;
  }
  .autoshade_rightbox {
    width: 51%;
  }
  .autoshade_rightbox {
    width: 51%;
    padding: 25px 8px;
  }
  .autoshade_btn ul li a.btn-border {
    width: 97px;
  }
}
@media screen and (max-width: 575px) {
  .partner_sec ul li:nth-last-of-type(2) {
    margin-right: 15px;
    max-width: 130px;
  }
  .partner_sec ul li:last-child {
    margin-right: 0px;
    max-width: 130px;
  }
  .banner_txt {
    padding: 30px 20px;
  }
  .banner_txt input[type="text"] {
    width: 65%;
    margin-right: 15px;
  }
  .banner_txt input[type="submit"] {
    padding: 5px 15px;
  }
  .service_list ul li {
    width: 29%;
  }
  .video_sec {
    padding-bottom: 70px;
  }
  .autoshade_leftbox {
    width: 100%;
  }
  .autoshade_rightbox {
    width: 100%;
  }
  .isotope-item {
    width: 99%;
    margin: 0;
    margin-bottom: 20px;
  }
  .table_sec {
    width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .open .nav-menu {
    padding: 25px 20px 0px;
  }
  .banner_txt h3 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .banner_txt input[type="text"] {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .service_list ul {
    display: block;
  }
  .service_list ul li {
    width: 100%;
    margin: 0 0 20px;
  }
  .video_box > img {
    height: 250px;
    object-fit: cover;
  }
  .partner_sec {
    padding: 30px 0px 50px;
  }
  .partner_sec ul {
    display: block;
  }
  .partner_sec ul li {
    display: block;
    margin: 0;
    margin-bottom: 20px;
    text-align: center;
  }
  .partner_sec ul li:nth-last-of-type(2) {
    max-width: 250px;
    margin: 0 auto;
  }
  .partner_sec ul li:last-child {
    max-width: 250px;
    margin: 0 auto;
  }
  .service_list ul li,
  .main_sec .service_list ul {
    display: block;
  }
  .service_list ul li,
  .main_sec .service_list ul li {
    width: 100%;
    margin: 0 0 20px;
  }
  .footer_txt img {
    width: 40px;
  }
  .footer_txt {
    margin-right: 0;
    display: block;
    margin-bottom: 20px;
  }
  .footer_top {
    padding-bottom: 50px;
  }
  .video_sec h3,
  .car_sec h2,
  .partner_title h3,
  .inner_banner_title h3,
  .autoshade_title h2,
  .review_sec h3,
  .auto_office h3 {
    font-size: 26px;
  }
  .repair_iframe iframe {
    height: 190px;
  }
  .repair_videobox h4 {
    margin-bottom: 10px;
  }
  .repair_left h4,
  .repair_list h4,
  .repair_videobox h4,
  .amsterdam_right h4 {
    font-size: 20px;
  }
  .amsterdam_logo ul li {
    margin-right: 30px;
  }
  .amsterdam_address ul li a img {
    width: 22px;
  }
  .amsterdam_btn ul li a.btn-border {
    width: 153px;
  }
  .amsterdam_right h5 {
    font-size: 18px;
  }
  .autoshade_leftbox {
    padding: 20px 10px;
  }
  .autoshade_rightbox {
    padding: 20px 10px;
  }
  .autoshade_btn ul li {
    margin-right: 2px;
  }
  .autoshade_txtright strong {
    font-size: 16px;
    width: 40px;
    height: 39px;
    line-height: 39px;
  }
  .autoshade_loadbtn a {
    padding: 14px 20px;
  }
  .amsterdam_time ul li em {
    font-size: 19px;
    width: 45%;
  }
  .amsterdam_time ul li span {
    font-size: 19px;
    width: 53%;
  }
  .amsterdam_time h4 {
    font-size: 18px;
  }
  .inner_banner2 .inner_banner_title h3 {
    margin-bottom: 25px;
  }
  .inner_banner2 {
    margin-bottom: 30px;
  }
  .total_leftbox {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .total_rightbox p {
    font-size: 17px;
  }
  .average_main ul li {
    width: 28%;
  }
  .customer_main {
    margin-bottom: 40px;
  }
  .grid_img {
    width: 60px;
    height: 60px;
    margin-right: 6px;
  }
  .grid_img span {
    font-size: 20px;
    padding: 13px 15px;
  }
  #load-more {
    padding: 15px 30px;
  }
  .auto_office h3 {
    margin-bottom: 30px;
  }
  .autoshade_btn ul li a.btn-border {
    width: 95px;
    padding: 11px 4px;
  }
  .nav_btn a {
    padding: 10px 8px;
  }
}
@media screen and (max-width: 380px) {
  .amsterdam_btn ul li {
    display: block;
    margin: 0 0 10px;
  }
  .amsterdam_btn ul li a.btn-border {
    width: 155px;
  }
}

@media screen and (min-width: 380px) and (min-width: 1080px) {
  .mobile-view {
    display: none;
  }
}
