.header {
    padding: 20px 0px;
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    height: 96px !important;
    border-bottom: 1px solid rgb(187, 187, 187);

    .container {
        max-width: 1300px;
    }
}
.navbar {
    padding: 0;
    display: flex;
}
.navbar-dark .navbar-brand {
    width: 140px;
    margin-right: 55px;
    padding: 0;
}
.navbar-collapse {
    display: flex;
    flex-basis: auto;
    flex-grow: 1;
    align-items: center;
}
.navbar-nav {
    display: flex;
    flex-direction: row;
    flex-grow:1;
}
.nav-menu ul {
    margin-right: 20px;
}
.nav-menu ul li {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    flex-grow: 1;

    &.active-menu-item a {
        color: #000b8d;
        font-weight: 700;
    }
}
.nav-menu ul li a {
    color: #000000;

    &:hover{
        color: #000b8d;
        font-weight: 700;
    }

}
#main {
    margin-top: 96px;
}

.nav_btn a {
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    font-weight: 700;
    padding: 14px 12px;
    border-radius: 2px;
    background-color: #f0ad4e;
    display: inline-block;
}
.nav_btn a:hover {
    background-color: #000b8d;
}
.nav_log a {
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    font-weight: 400;
    margin-left: 15px;
}
.nav_log a:hover {
    color: #000b8d;
}
.nav_log a img {
    width: 25px;
    margin-right: 5px;
}

.nav_search {
    margin-right: 15px;

    .result-container {
        position: absolute;
        background-color: #fff;
        box-shadow: 0 10px 10px rgb(0, 0, 0, 30%);
        border-radius: 5px;
        padding: 10px 0;
        display: flex;
        flex-direction: column;

        a {
            padding: 5px 15px;

            &:hover {
                background-color: #ddd;
            }

            &:not(:last-of-type) {
                border-bottom: solid 1px #ccc;
            }
        }
    }
}

.searchbox {
    width: 185px;
    position: relative;
}
.searchbox input[type="text"] {
    margin: 0 auto;
    width: 100%;
    outline: none;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    font-weight: 400;
    border: 1px solid #e5e5e5;
    border-radius: 0;
    padding: 5px 40px 5px 8px;
    background: #f6f6f6;
}
.searchbox input[type="submit"] {
    background: url("/images/search1.svg") #000000 no-repeat;
    border-width: 0px;
    width: 34px;
    height: 34px;
    position: absolute;
    right: 0px;
    top: 0px;
    background-size: 20px;
    background-position: center;
    color: transparent;
}
.searchbox input::-moz-placeholder {
    color: #000000;
}
.searchbox input::placeholder {
    color: #000000;
}
#button-submit:hover {
    cursor: pointer;
}
/**********nav-icon**********/
.nav-icon {
    display: none;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 9;
    width: 30px;
    height: 19px;
    position: absolute;
    top: 16px;
    right: 0px;
    border-radius: 0;
}
.nav-icon span {
    display: block;
    position: absolute;
    height: 3px;
    background: #000000;
    opacity: 1;
    left: 6px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    border-radius: 20px;
}
.nav-icon span:nth-child(1) {
    top: 0px;
    width: 30px;
    left: auto;
}
.nav-icon span:nth-child(2) {
    top: 9px;
    width: 30px;
    left: auto;
}
.nav-icon span:nth-child(3) {
    top: 18px;
    width: 30px;
    left: auto;
}
.nav-icon.open span:nth-child(1) {
    top: 10px;
    right: 0px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}
.nav-icon.open span:nth-child(2) {
    opacity: 0;
}

.nav-icon.open span:nth-child(3) {
    top: 10px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
    width: 30px;
    right: 0px;
}



@media screen and (max-width: 1080px) {
    #main-navigation {
        align-items: center;
        padding-right:0;

        .nav-icon {
            position:unset;
        }

        .nav_btn {
            margin-left:auto;
            margin-bottom:5px;
        }

        .dropdown-menu-lg-end {
            right:auto;
            left:0;
        }

        .nav_log.dropdown {

            .dropdown-menu {

                &:after {
                    right:auto;
                    left:10px;
                }

                &:before {
                    right:auto;
                    left:9px;
                }

            }
        }
    }
}

@media screen and (max-width:400px) {
    #main-navigation { 
        .nav_btn {
            margin-right:15px!important;
        }
    }
}