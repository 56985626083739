img {
  max-width: 100%;
}
*,
ul,
li,
p,
h4,
h6,
h5,
h2,
h3,
h1,
ol {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  overflow-x: hidden;
  margin-top: 0px !important;
}
a {
  text-decoration: none;
  color: $asn-blue;
}
a,
a:hover {
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}
a:hover {
  text-decoration: none !important;
  transition: 0.3s all ease-in-out;
}
a:focus {
  text-decoration: none !important;
  transition: 0.3s all ease-in-out;
}
li {
  list-style: none;
}
a {
  transition: all 0.3s;
}
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #000000;
  background-color: #ffffff;
}
p {
  font-size: 16px;
  color: #000000;
  font-weight: 400;
}
strong {
  font-weight: 700;
}
.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.justify-content-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-content-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.align-items-end {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
}
.align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
@font-face {
  font-family: "MyriadPro-Regular";
  src: url("../fonts/MyriadPro-Regular.eot");
  src: url("../fonts/MyriadPro-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MyriadPro-Regular.woff2") format("woff2"), url("../fonts/MyriadPro-Regular.woff") format("woff"),
    url("../fonts/MyriadPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.partner-list {
  width: 100%;
  margin-top: 156px;
}
.partner-list p {
  width: 60%;
  margin: auto;
  margin-top: 30px;
}
.partner-list div {
  margin: auto;
}
.vacature-form {
  width: 61%;
  height: auto;
  margin: auto;
  margin-top: 40px;
}
.vacature_title {
  text-align: center;
  margin-bottom: 20px;
}
.mobile-view {
  margin-top: 5px !important;
  margin-right: 40px !important;
}

.vestiging-info {
  margin-bottom: 20px;
}
.vestiging-info h4,
.vestiging-info h5,
.vestiging-info p {
  width: 80%;
  margin: auto;
}
.vestiging-info h4 {
  font-weight: 600;
}
.vestiging-info h5 {
  font-weight: 600;
  margin-top: 20px;
}

.vestiging-info div {
  float: left;
}

@media screen and (max-width: 500px) {
  #mail-app {

    .tab-content > .row > div {
      padding-right:12px!important;

      .mail-body {
        padding: 0 10px;
      }
    }

  }
}
