/*
  Maps info window
*/

.infowindow {

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    img {
      max-width: 20px;
      margin-right: 5px;
    }
  }
  
  .addr-2 {
    margin-bottom: 5px;
  }

}