#map,
#home-map {
  width: 100%;
  height: 525px;
}
.banner_main {
  display: flex;
  position: absolute;
  left: 0;
  top: 50%;
  width: auto;
  transform: translateY(-50%);
}
.banner_txt {
  max-width: 454px;
  border-radius: 5px;
  background-color: $asn-dark-blue;
  padding: 30px 30px;
  top: 100%;
}
.banner_txt p {
  color: white;
}

.banner_txt h1,
.banner_txt h2,
.banner_txt h3 {
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 15px;
}

.map-wrapper {
  .banner_txt p {
    margin-bottom:20px;
  }
}

.banner_txt form {
  display: flex;
}
.banner_txt input[type="text"] {
  margin: 0 auto;
  outline: none;
  font-size: 15px;
  color: #999999;
  font-weight: 300;
  font-weight: 400;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #999999;
  padding: 7px 0px;
  background: #000b8d;
  margin-right: 25px;
  display: inline-block;
  vertical-align: top;
  flex-grow:1;
  color:#fff;
}

.banner_txt input[type="text"]:focus{
  border-color:#fff;
}

.banner_txt input[type="submit"] {
  background: #ffffff;
  padding: .4em .8em;
  border: 0px;
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  display: inline-block;
  vertical-align: top;
  border-radius: 5px;
  transition: 0.3s all ease-in-out;
}

.banner_txt input[type="submit"]:hover {
  background: #00aeef;
  color: #ffffff;
}


@media screen and (max-width:500px){
  .map-wrapper {
    .banner_main {
      position:unset;
      transform: unset;

      .banner_txt {
        width:100%;
        max-width:100%;
        border-radius: 0;
        margin-left:0!important;
      }
    }
  }
}