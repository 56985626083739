.main_sec {
  border-top: 1px solid #dfdfdf;
  margin-top: 96px;
  padding-bottom: 50px;
}
.inner_banner {
  padding: 55px 0px 0px;
}
.inner_banner_title {
  margin-bottom: 30px;
}
.inner_banner_title h3 {
  font-size: 36px;
  color: #000000;
  font-weight: 800;
  text-align: center;
  padding-bottom: 20px;
  position: relative;
}
.inner_banner_title h3:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 232px;
  height: 2px;
  margin: 0 auto;
  background-color: #000b8d;
}
.inner_banner_img img {
  width: 100%;
}
.main_sec .container {
  max-width: 1040px;
  width: 100%;
  margin: 0 auto;
}
.main_sec .service_list ul li {
  margin-right: 6px;
  width: 19%;
}
.repair_main {
  padding-top: 50px;
  padding-bottom: 35px;
}
.repair_left {
  padding-right: 44px;
}
.repair_left h4 {
  font-size: 24px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 20px;
}
.repair_left p {
  font-size: 16px;
  color: #000000;
  font-weight: 300;
  margin-bottom: 20px;
}
.repair_left p span {
  font-weight: 500;
}
.repair_btn {
  margin-bottom: 60px;
}
.repair_btn a {
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 2px;
  background-color: #f0ad4e;
  padding: 13px 10px;
}
.repair_list h4 {
  font-size: 24px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 32px;
}
.repair_list ul {
  padding: 0;
  margin: 0;
}
.repair_list ul li {
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  font-weight: 400;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}
.repair_list ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 21px;
  height: 15px;
  background: url("../images/arrow.png") no-repeat;
  background-size: 100%;
}
.repair_videobox h4 {
  font-size: 24px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 25px;
}
.repair_iframe {
  margin-bottom: 22px;
}
.repair_iframe iframe {
  width: 100%;
  height: 300px;
}
.repair_txt {
  position: relative;
  padding-bottom: 70px;
}
.repair_txt p {
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  font-weight: 300;
  margin-bottom: 20px;
}
.repair_txt ul {
  margin-bottom: 5px;
}
.repair_txt ul li {
  display: block;
}
.repair_txt ul li span {
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  font-weight: 300;
  width: 130px;
  display: inline-block;
  vertical-align: top;
}
.repair_txt ul li em {
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  vertical-align: top;
}
.repair_txt a.repair_txtbtn {
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 2px;
  background-color: #f0ad4e;
  padding: 13px 10px;
  position: absolute;
  bottom: 0;
  left: 0;
}


.insurance-companies-container, .inner-page {
  margin-top: 156px;
}

