.h3 {
  font-family: "Open Sans", sans-serif;
  font-size: 28px;
  font-weight: 500;
}

.h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 500;
}

#ui-datepicker-div {
  z-index: 999999 !important
}

@for $i from 0 to 6 {
  .mt-#{$i} {
    margin-top: #{$i}rem !important;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}rem !important;
  }

  .mr-#{$i} {
    margin-right: #{$i}rem !important;
  }

  .ml-#{$i} {
    margin-left: #{$i}rem !important;
  }

  .my-#{$i} {
    margin-top: #{$i}rem!important;
    margin-bottom: #{$i}rem!important;
  }

  .mx-#{$i} {
    margin-left: #{$i}rem!important;
    margin-right: #{$i}rem!important;
  }

  .pt-#{$i} {
    padding-top: #{$i}rem !important;
  }

  .pb-#{$i} {
    padding-bottom: #{$i}rem !important;
  }

  .pl-#{$i} {
    padding-left: #{$i}rem !important;
  }

  .pr-#{$i} {
    padding-right: #{$i}rem !important;
  }
}

.uppercase {
  text-transform: uppercase;
}

.has-load.loading {
  position: relative;
  opacity: 0.7;
  pointer-events: none;
  overflow: hidden;
}

.has-load.loading::after{
  content: '';
  position: absolute;
  height: 3px; 
  background: #586B99;
  bottom:0;
  animation: progress 1.5s infinite ease-in-out;
}

*[data-toggle="popup"] {
  cursor: pointer;
  user-select: none;
}