$custom-btn-colors: (
  'gold': $gold,
  'asn': $asn-blue
);

@each $color-name, $color-value in $custom-btn-colors {
  .btn-#{$color-name} {
    @include button-variant($color-value, $color-value, #fff);
  }

  .btn-outline-#{color-name} {
    @include button-outline-variant($color-value);
  }
}