.vestigingen_titel {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 20px;
	justify-content: center;
}
.amsterdam_main {
	padding: 60px 0px;
	margin-top: 20px !important;
}
.amsterdam_right {
	padding-right: 13px;
}
.amsterdam_right h4 {
	font-size: 24px;
	color: #000000;
	font-weight: 700;
	margin-bottom: 20px;
}
.amsterdam_right p {
	font-size: 16px;
	color: #000000;
	font-weight: 300;
	margin-bottom: 20px;
}
.amsterdam_right h5 {
	font-size: 20px;
	color: #000000;
	font-weight: 700;
}
.amsterdam_btn {
	margin-bottom: 30px;
}
.amsterdam_btn ul {
	text-align: left;
}
.amsterdam_btn ul li {
	display: inline-block;
	vertical-align: top;
	margin-right: 3px;
}
.amsterdam_btn ul li:last-child {
	margin-right: 0px;
}
.amsterdam_btn ul li a.btn-border {
	display: inline-block;
	border-radius: 2px;
	background-color: #ffffff;
	border: 1px solid #999999;
	font-size: 17px;
	color: #000000;
	font-weight: 300;
	padding: 10px 15px;
	width: 161px;
	height: 47px;
	text-align: center;
}
.amsterdam_btn ul li a.btn-border img {
	width: 23px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 20px;
}
.amsterdam_btn ul li a.btn-border:hover {
	background-color: #f0ad4e;
	border: 1px solid #f0ad4e;
}
.amsterdam_btn ul li a.btn {
	display: inline-block;
	font-size: 16px;
	line-height: 22px;
	color: #ffffff;
	padding: 13px 3px;
	width: 156px;
	height: 48px;
	border-radius: 2px;
	background-color: #f0ad4e;
	text-align: center;
	border: 1px solid #f0ad4e;
}
.amsterdam_btn ul li a.btn:hover {
	background-color: #f9f9f9;
	border: 1px solid #999999;
	color: #000000;
}
.amsterdam_map {
	margin-bottom: 15px;
}
.amsterdam_map #location-map {
	width: 400px;
	height: 300px;
}
.amsterdam_map img {
	width: 100%;
}
.amsterdam_address {
	margin-bottom: 30px;
}
.amsterdam_address h5 {
	font-size: 18px;
	color: #000000;
	font-weight: 400;
	margin-bottom: 10px;
}
.amsterdam_address ul {
	display: grid;
}
.amsterdam_address ul li {
	display: inline-block;
	vertical-align: middle;
}
.amsterdam_address ul li a {
	font-size: 18px;
	line-height: 30px;
	color: #000000;
	font-weight: 300;
}
.amsterdam_address ul li a img {
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
	width: 28px;
}
.amsterdam_time {
	margin-bottom: 30px;
}
.amsterdam_time h4 {
	display: inline-block;
	vertical-align: bottom;
	font-size: 19px;
	font-weight: 600;
	margin-bottom: 14px;
}
.amsterdam_time img {
	width: 22px;
	margin-right: 4px;
	display: inline-block;
	vertical-align: bottom;
	position: relative;
	top: -3px;
}
.amsterdam_time ul li {
	display: block;
	padding: 3px 5px;
}
.amsterdam_time ul li.active {
	background-color: #91e3ff;
}
.amsterdam_time ul li:hover {
	background-color: #91e3ff;
	transition: 0.3s all ease-in-out;
}
.amsterdam_time ul li em {
	display: inline-block;
	vertical-align: top;
	font-style: normal;
	font-weight: 300;
	font-size: 21px;
	width: 41%;
	font-family: "MyriadPro-Regular", sans-serif;
}
.amsterdam_time ul li span {
	display: inline-block;
	vertical-align: top;
	font-size: 21px;
	font-weight: 300;
	width: 57%;
	font-family: "MyriadPro-Regular", sans-serif;
}
/* .amsterdam_logo {float: left;} */
.amsterdam_logo ul {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.amsterdam_logo ul li {
	display: inline-block;
	vertical-align: middle;
	margin-right: 80px;
	width: 39%;
}
.amsterdam_logo ul li:last-child {
	margin-right: 0px;
}
.amsterdam_logo ul li img {
	width: 158px;
	margin: auto;
}
.inn_video {
	background-image: linear-gradient(0deg, #f3f3f3 65%, transparent 0%);
	padding-bottom: 180px;
}
.inn_video .video_box {
	margin-bottom: 15px;
}
.inn_video p {
	font-size: 17px;
	color: #000000;
	font-weight: 400;
	text-align: center;
}
.inn_video .video_main {
	margin-top: -200px;
}
.inner_banner2 {
	margin-bottom: 80px;
}
.inner_banner2 .inner_banner_title h3 {
	margin-bottom: 35px;
}
.inner_banner2 .inner_banner_title p {
	font-size: 18px;
	color: #000000;
	font-weight: 400;
	text-align: center;
}
.page_title_search h2 {
	margin: 0 !important;
}
.customer_main {
	margin-top: 75px;
}
.customer_left {
	padding-right: 15px;
}
.customer_left p {
	font-size: 16px;
	line-height: 21px;
	color: #000000;
	font-weight: 300;
	margin-bottom: 20px;
}
.customer_right {
	padding-left: 100px;
	text-align: center;
}
.customer_right h5 {
	font-size: 18px;
	color: #000000;
	font-weight: 600;
	margin-bottom: 25px;
}
.total_box {
	margin-bottom: 35px;
}
.total_leftbox {
	display: inline-block;
	vertical-align: middle;
	margin-right: 30px;
	text-align: center;
}
.total_leftbox img {
	width: 55px;
	margin-bottom: 8px;
}
.total_leftbox p {
	font-size: 16px;
	color: #000000;
	font-weight: 300;
	line-height: normal;
}
.total_leftbox p strong {
	font-size: 24px;
	color: #000b8d;
	font-weight: 700;
	display: block;
	line-height: normal;
}
.total_rightbox {
	display: inline-block;
	vertical-align: middle;
}
.total_rightbox p {
	font-weight: 500;
	font-size: 18px;
}
.total_rightbox p a {
	color: #5abdec;
}
.total_rightbox p img {
	display: inline-block;
	vertical-align: middle;
	width: 38px;
	margin-right: 3px;
}
.average_container {
	display: flex;
	justify-content: center;
	margin-top: 30px;
}
.average_main h5 {
	font-size: 18px;
	color: #000000;
	font-weight: 600;
	margin-bottom: 15px;
	text-align: center;
}
.average_main ul li {
	display: inline-block;
	vertical-align: top;
	margin-right: 50px;
	width: 24%;
}
.average_main ul li:last-child {
	margin-right: 0px;
}
.average_main ul li .average_box {
	text-align: center;
}
.average_main ul li .average_box .average_count {
	background: url("/images/img13.png") no-repeat;
	width: 65px;
	height: 65px;
	background-size: 100%;
	margin: 0 auto;
}
.average_main ul li .average_box .average_count span {
	font-size: 22px;
	line-height: 21px;
	color: #ffffff;
	font-weight: 700;
	display: inline-block;
	padding: 15px 15px;
}
.average_main ul li .average_box .average_txt img {
	width: 66px;
	margin-bottom: 5px;
}
.average_main ul li .average_box .average_txt p {
	font-size: 16px;
	color: #000b8d;
	font-weight: 300;
}
/***********review_sec-start***************/
.review_sec {
	padding-bottom: 135px;
}
.review_sec h3 {
	font-size: 30px;
	color: #000000;
	font-weight: 800;
	text-align: center;
	margin-bottom: 55px;
}
.grid_sec {
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	padding: 30px 0px;
}
.grid-item .grid_inner {
	position: relative;
	width: 100%;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center center;
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	padding: 20px;
}
.grid_title {
	padding-bottom: 10px;
}
#load-more {
	border-radius: 5px;
	background-color: #00aeef;
	font-size: 16px;
	color: #ffffff;
	font-weight: 400;
	transition: 0.5s ease;
	border: 0;
	padding: 19px 33px;
}
.grid-sizer {
	float: left;
}
.isotope-items-wrap {
	margin-bottom: 50px;
}
.isotope-item {
	width: 31.7%;
	border-radius: 5px;
	background-color: #f3f3f3;
	padding: 11px 13px;
	margin: 8px;
}
.box {
	width: 100%;
	padding: 20px;
	color: #ffffff;
	padding: 11px 13px;
}
.text-box .box {
	background-color: green;
}
.hidden {
	display: none;
}
.grid_img {
	background: url("/images/img13.png") no-repeat;
	width: 65px;
	height: 65px;
	background-size: 100%;
	margin: 0 auto;
	display: inline-block;
	vertical-align: middle;
	margin-right: 8px;
}
.grid_img span {
	font-size: 22px;
	line-height: 21px;
	color: #ffffff;
	font-weight: 700;
	display: flex;
	justify-content: center;
	margin-top: 15px;
}
.grid_txt {
	display: inline-block;
	vertical-align: middle;
	width: 73%;
	padding-top: 25px;
}
.grid_txt h6 {
	font-size: 15px;
	line-height: 21px;
	color: #000b8d;
	font-weight: 700;
	margin-bottom: 5px;
}
.grid_txt span {
	font-size: 15px;
	line-height: 21px;
	color: #000000;
	font-weight: 400;
	display: inline-block;
}
.grid_sec_text p {
	font-size: 15px;
	line-height: 21px;
	color: #000000;
	font-weight: 300;
}

.location-tevreden-score {
	display: flex;
	flex-direction: column;
	text-align: center;
	background-color: #03b2ef;
	border-radius: 100%;
	max-width: 158px;
	height: 158px;
	align-items: center;
	justify-content: center;
	box-shadow: 0 2px 4px rgb(0 0 0);
	transform: rotate(-6deg);
	color: #fff;
	font-weight: 700;
	margin: 0 auto;

	.title {
		font-size: 12px;
	}

	.score {
		font-size: 19px;
	}

	.tevreden-logo {
		max-width: 72px;
	}
}

.stars {
	width: 100%;

	ul.star-list {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		width: 100%;
		padding: 0 10px;

		li {
			width: auto !important;
			margin: 0 !important;

			img {
				width: 23px;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.body-locations {
		.amsterdam_map {
			#location-map {
				width: 100%;
			}
		}

		.amsterdam_logo {
			margin-bottom: 30px;
		}
	}

	.body-reactions {
		.grid_img {
			span {
				margin-top: 0;
			}
		}

		.customer_main {
			margin-bottom: 0px;
			margin-top:0;
		}

		
	}
}
