.appointment-popup {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  justify-content: center;
  align-items: center;
  opacity: 0;
  max-height: 100%;
  overflow-y: auto;
  padding: 5px;
}

#appointmentForm {
  background-color: #fff;
  border: solid 1px #ccc;
  border-radius: 10px;
  padding: 0 20px;
  max-width: 780px;
  width: 100%;
  position: relative;
  z-index: 2;
  max-height: 100%;
  overflow: auto;
  .cal.textfield {
    position: relative;
  }
  .cal.textfield:after {
    content: "\f133";
    cursor: text;
    font-family: FontAwesome;
    margin-top: -11px;
    position: absolute;
    right: 10px;
    top: 50%;
    width: 20px;
    z-index: 0;
    pointer-events: none;
  }

  &::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #ccc; /* color of the tracking area */
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #20419a; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 0; /* creates padding around scroll thumb */
  }

  .btn-close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }

  h3 {
    border-bottom: solid 1px #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .popup-progress-bar {
    position: relative;

    .progress-bar-labels {
      position: absolute;
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }

    .progress {
      height: 23px;
      display: flex;

      .progress-bar {
        float: unset;

        &.step-1 {
          width: 0%;
        }

        &.step-2 {
          width: 33.333%;
        }

        &.step-3 {
          width: 66.666%;
        }

        &.send {
          width: 100%;
        }
      }
    }
  }
  .input-group {
    width: 100%;
  }
  .keuze-afspraak {
    .radio {
      margin-bottom: 10px;
    }
  }

  .company-search-wrapper {
    position: relative;

    .company-results {
      display: none;
      position: absolute;
      top: 100%;
      margin-top: 5px;
      border: solid 1px #ccc;
      border-radius: 10px;
      background-color: #fff;
      width: 100%;
      box-shadow: 0 7px 10px rgba(0, 0, 0, 0.2);
      overflow: hidden;

      .company-result {
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
          background-color: #ccc;
        }

        &:not(:last-of-type) {
          border-bottom: solid 1px #ccc;
        }
      }
    }
  }

  #app_file {
    min-height: 100px;
  }

  #appointment-map {
    height: 300px;
    width: 100%;
    border-radius: 10px;
  }

  #appointment-map div[role="dialog"] {
    display: flex;
    flex-direction: row-reverse;
  }

  #appointment-map .infowindow {
    padding-top: 9px;
  }
}

.popup > .close-popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

body.popup-open {
  overflow: hidden;
}

button[type="submit"].loading {
  position: relative;
  opacity: 0.7;
  pointer-events: none;
  /* border-color:#1ca4dd; */
  overflow: hidden;
}
button[type="submit"].loading:focus {
  outline: none;
}
button[type="submit"].loading::after {
  content: "";
  position: absolute;
  height: 3px;
  background: #1e3465;
  bottom: 0;
  animation: progress 1.5s infinite ease-in-out;
}

@keyframes progress {
  0% {
    left: 0;
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    right: 0;
    width: 0;
  }
}

body.popup-open {
  overflow: hidden;
}
