.cc-window {
  .cc-message {
    height: 100px;
    overflow: hidden;
  }

  &.cc-auto-height {
    .cc-message {
      height:auto;
    }
  }

  .toggle-cookie-text{
    position: absolute;
    top: 5px;
    width: 40px;
    right: 5px;
    cursor: pointer;

    &.open {
      transform: rotate(180deg);
    }
  }
}